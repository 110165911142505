<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
     
      <CRow class="justify-content-center">
        <CCol md="7">
           <CCardGroup>
             <CCard
              text-color="white"
              class="text-center py-5 d-md-down-none logo-bg"
             >
            <CCardBody>
             <el-image
              style="width: 160px"
              src="img/CompanyLogo.png"
              fit="fit"
              >
              </el-image>
            </CCardBody>

              
            </CCard>
            <CCard
               class="p-3"
              body-wrapper
            >
           <CCardBody>
                <CForm>
                   
                  <h2>LucidiTV INVOICE</h2>

                  <p></p>
                  <p></p>
                  
                  <CRow>
                    <CCol col="6" class="text-center">
                      <CButton color="primary" class="px-2" @click="login"><h5>Login</h5></CButton>
                    </CCol>
                   </CRow>
                </CForm>
              </CCardBody>
              
            </CCard>
          </CCardGroup>

         
         
             
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { getAuth, signInWithRedirect, GoogleAuthProvider, getRedirectResult,signInWithPopup } from "firebase/auth";
var auth = getAuth();
export default {
  name: 'Login',
  
  data () {
    return {
      isLoading: true
    }
  },
  created(){
  },
  methods:{
    login(){
      const self = this;
      var provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (result.credential) {
      self.$router.replace('/');
      }
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // IdP data available using getAdditionalUserInfo(result)
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });
    }
  }
}
</script>
<style scoped>
.logo-bg {
  background-color:#000000B3;
}
</style>
